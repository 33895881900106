<template>
  <div class="flex flex-row pl-8 stats">
    <!--card one-->
    <div class="   flex justify-center items-center  ">
      <div class="flex flex-row justify-start items-center w-full">
        <div class="flex flex-col">
          <router-link :to="'declaration/declarations'" style="text-decoration: none;">
            <div class="flex">
              <img class="icon-dashboard" style=" width:28px; height:28px;" :src="getAssetPath('declaration_green.svg')"
                   alt=""/>
              <label class="text-counter">{{ userStats.declarationCount }}</label>
            </div>
          </router-link>
          <!-- step label-->
          <span class="label-text flex  items-start h-11 w-20">  {{ $t('dashboard.top_header.declaration') }}  </span>
        </div>
        <!-- line between steps-->
        <hr class="verticle-line"/>
      </div>
    </div>
    <!-- card two -->
    <div class=" pl-6 flex justify-center items-center  ">
      <div class="flex flex-row justify-start items-center w-full">
        <div class="flex flex-col ">
          <router-link :to="'property/list'" style="text-decoration: none;">
            <div class="flex">
              <img class="icon-dashboard" style=" width:28px; height:28px;" :src="getAssetPath('home-green.svg')"
                   alt=""/>
              <label class="text-counter">{{ userStats.propertyCount }}</label>
            </div>
          </router-link>
          <span class="label-text flex  items-start h-11 w-20">  {{ $t('dashboard.top_header.properties') }}  </span>
        </div>
        <!-- line between steps-->
        <hr class="verticle-line"/>
      </div>
    </div>
    <!-- card three -->
    <div class=" pl-6 flex " v-if="!$isBusiness">
      <div class="flex flex-row justify-start items-center w-full">
        <div class="flex flex-col ">
          <router-link :to="'client/list'" style="text-decoration: none;">
            <div class="flex">
              <img class="icon-dashboard" style=" width:31px;height:28px;" :src="getAssetPath('clients_stats.svg')"/>
              <label class="text-counter">{{ userStats.clientCount }}</label>
            </div>
          </router-link>
          <!-- step label-->
          <span class="label-text flex  items-start h-11 w-20">  {{ $t('dashboard.top_header.clients') }}  </span>
        </div>
        <!-- line between steps -->
        <hr class="verticle-line"/>
      </div>
    </div>
    <!-- card four -->
    <div
      v-if="getAccountPayType === 'paid' || getAccountPLanId > '0'"
      class="pl-6 flex justify-center items-center"
    >
      <div class="flex flex-row justify-start items-center w-full">
        <div class="flex flex-col">
          <router-link :to="billingLink()" style="text-decoration: none;">
            <div class="flex">
              <img class="icon-dashboard" style=" width:28px; height:28px;" :src="getAssetPath('calander_green.svg')"
                   alt=""/>
              <label class="text-counter">{{ getCurrentPlanName }}</label>
            </div>
          </router-link>
          <!-- step label-->
          <span class="label-text flex  items-start h-11"> {{ $t('settings.billing.bis') }} {{
              getCurrentPlanEndDate
            }}  </span>
        </div>
        <!-- line between steps -->
        <hr class="verticle-line"/>
      </div>
    </div>
    <div
      v-else
      class="flex justify-center items-center"
    >
      <div class="pl-6 flex justify-center items-center">
        <div class="flex flex-row justify-start items-center w-full">
          <div class="flex flex-col">
            <router-link
              :to="'setting/BillingTrial'"
              style="text-decoration: none;"
            >
              <div class="flex">
                <img
                  class="icon-dashboard"
                  style="width:28px; height:28px;"
                  :src="getAssetPath('calander_green.svg')"
                  alt=""
                />
                <label class="text-counter">Testphase</label>
              </div>
            </router-link>
            <!-- step label-->
            <span class="label-text flex items-start h-11">
              {{ $t('settings.billing.bis') }} {{ getCurrentPlanTrialEndDate}}
            </span>
          </div>
          <!-- line between steps -->
          <hr class="verticle-line"/>
        </div>
      </div>
      <div class="pl-6 flex justify-center items-center">
        <div class="flex flex-row justify-start items-center w-full">
          <div class="flex flex-col">
            <Button
              :text="$t('settings.billing.book_now')"
              :icon="require('@/assets/book_now.svg')"
              class="font-semibold"
              @click="goToBillingTrialPage()"
            />
            <span class="flex items-start h-11 mt-2 text-muted_black font-semibold">
              {{ $t('settings.billing.book_now_tipp') }}
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {UserService} from "@/services/user.service";

export default {
  name: "Stats",
  data() {
    return {
      userStats: {
        clientCount: 0,
        propertyCount: 0,
        declarationCount: 0
      },
      billingLink() {
        if(this.billingAvailable()) {
          return 'setting/billing';
        }
        return '';
      },
      billingAvailable() {
        return !this.getCurrentUser().billing_by_partner;
      },
      goToBillingTrialPage() {
        this.$router.push('/setting/BillingTrial');
      },
    }
  },
  mounted() {
    (new UserService()).getCurrentUserStats().then((stats) => {
      this.userStats = stats;
    });
  },
  computed: {
    ...mapGetters("user", {
      "currentUser": "getCurrentUser"
    }),
    getCurrentPlanName() {
      return this.currentUser.plan_name;
    },
    getCurrentPlanEndDate() {
      return this.currentUser.end_date;
    },
    getCurrentPlanTrialEndDate() {
      return this.currentUser.trial_end;
    },
    getAccountPayType() {
      return this.currentUser.account_pay_type;
    },
    getAccountPLanId() {
      return this.currentUser.plan_uid;
    }
  }
}
</script>

<style scoped lang="scss">
.circle {
  @apply rounded-full flex items-center justify-center flex    m-2;

}

.verticle-line {
  width: 1px;
  height: 56px;
  border: 1px solid #e6e6e6;
  background-color: #e6e6e6;
  color: #aeaeae;
  margin-left: 18px;
  margin-bottom: 10px;
  margin-top: -20px;
}

.line {
  margin-left: -18px;
  margin-right: -20px;
  width: 100%;
  @apply flex justify-center  h-full   border-2 border border-green;
  &.completed {
    border-color: $primary;
  }

  &.pending {
    border-color: $gray-400;
  }

}

.label-text {
  @include segoe-regular;
  color: #aeaeae;
  margin-top: 5px;
}

.text-counter {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 18px;
  margin-left: 10px;
}
</style>
